<script>
export default {
  components: {
    Modal: () => import("@/components/modals/Modal.vue"),
    MonitorCardToAssign: () => import("@/views/main/operationNow/MonitorCardToAssign"),
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    monitors() {
      let monitors = this.$store.getters["connectedUsers/getMonitors"];
      if (this.search) {
        monitors = monitors.filter((e) => e.user.toUpperCase().includes(this.search.toUpperCase()));
      }
      return monitors || [];
    },
  },
  methods: {
    close() {
      this.$store.state.user.usersToAssign = {};
    },
  },
};
</script>

<template>
  <Modal class="modalMonitor" :showMask="true" @onCancel="close">
    <template v-slot:header>
      <div class="modalMonitor__header">
        <h2 class="modalMonitor__title">Asignar</h2>
        <button class="modalMonitor__close" @click="close">
          <iconic class="modalMonitor__icon" name="close" />
        </button>
      </div>
      <div class="modalMonitor__searchContainer" v-if="(monitors || []).length || search">
        <input :class="['modalMonitor__search', search && 'modalMonitor__search--isSearch']" type="text" id="search" name="search" v-model="search" />
        <iconic class="modalMonitor__searchIcon" name="search-solid" />
      </div>
    </template>
    <template v-slot:body>
      <div class="modalMonitor__cardContent" v-if="(monitors || []).length">
        <MonitorCardToAssign class="modalMonitor__card" :user="monitor" :isModal="true" v-for="monitor in monitors" :key="monitor._id" />
      </div>
      <div class="modalMonitor__cardContent" v-else-if="!(monitors || []).length && search">
        <p>Ningún monitor coincide con la búsqueda.</p>
      </div>
      <div class="modalMonitor__cardContent" v-else>
        <p>Sin monitores disponibles.</p>
      </div>
    </template>
    <template v-slot:footer>
      <div></div>
    </template>
  </Modal>
</template>

<style lang="scss">
.modalMonitor {
  .modal-g__container {
    width: 100%;
    max-width: 320px;
  }
  &__header {
    @include Flex(row, space-between);
  }
  &__close {
    @include Flex(row);
    height: 35px;
    width: 35px;
    color: $primary-color;
    border: none;
    border-radius: 50%;
    background: none;
    cursor: pointer;
    &:hover {
      background: #bd09092e;
      transition: 0.3s ease-in-out;
    }
  }
  &__searchContainer,
  &__header,
  &__cardContent {
    width: 100%;
  }
  &__searchContainer {
    @include Flex(row, flex-start);
    position: relative;
  }
  &__search {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 25px;
    background-color: #5b59572b;
    color: black;
    font-size: 20px;
    transition: 0.7s ease-in-out;
    z-index: 15;
    &:hover,
    &:focus,
    &--isSearch {
      width: 100%;
      padding: $mpadding $mpadding * 2;
    }
  }
  &__searchIcon {
    position: absolute;
    top: 12px;
    left: 12px;
    font-size: 17px;
    z-index: 10;
  }
  &__cardContent {
    @include Flex(column, flex-start);
    text-align: center;
    max-height: calc(100vh - 100px);
    margin-top: $mpadding / 2;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      background-color: $alto;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $chicago;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
  }
  &__card {
    margin-top: $mpadding/2;
    width: 98%;
  }
}
</style>
